<template>
  <div class="container-fluid px-0 py-2" style="margin-bottom: 100px">
    <div class="col-12">
      <div class="p-dash-cliente-master">
        <div class="col-12 col-md-12 mx-auto">
          <div class="row">
            <div class="col-12 cancela-col-padding" v-if="!loading && custom">
              <div class="container-pedido">
                <div class="left-side">
                  <img
                    v-if="custom.enterprise"
                    :src="custom.enterprise.image"
                    class="img-fluid logo"
                    title="Logo"
                    style="max-height: 40px"
                  />

                  <h4>DETALHES DO PEDIDO</h4>

                  <CustomItemUser :custom="custom" />
                </div>
                <div class="right-side">
                  <div class="header-custom">
                    <h4>Minha personalização</h4>
                    <div class="total">
                      <span>TOTAL PEDIDO</span>
                      <p>R$ {{ formatMoney(custom.price) }}</p>
                    </div>
                  </div>
                  <div v-if="!loading_itens">
                    <div v-if="itens_personalization.length">
                      <div
                        data-anima="bottom"
                        class="itens-pedido"
                        v-for="(item_custom, index) in itens_personalization"
                        :key="item_custom.id"
                      >
                        <h5>
                          {{
                            item_custom.trigger
                              ? item_custom.trigger.name
                              : `PERSONALIZAÇÃO ${index + 1}`
                          }}
                        </h5>

                        <div class="item-pedido">
                          <img
                            :src="item_custom.image"
                            class="img-item"
                            alt=""
                          />
                          <img
                            :src="
                              select_itens_personalization[index]
                                ? select_itens_personalization[index].image
                                : ''
                            "
                            alt=""
                            class="img-pedra"
                          />
                          <div class="info-itens">
                            <div class="info-item">
                              <div class="item-dado">
                                <span>PERSONALIZAÇÃO</span>
                                <p>{{ item_custom.name }}</p>
                              </div>
                              <div class="item-dado">
                                <span>TIPO </span>
                                <p>
                                  {{
                                    select_itens_personalization[index]
                                      ? select_itens_personalization[index].name
                                      : "-"
                                  }}
                                </p>
                              </div>
                            </div>
                            <p class="obs-item">
                              {{
                                item_custom.trigger
                                  ? item_custom.trigger.subtitle
                                  : `-`
                              }}
                            </p>
                          </div>
                          <div>
                            <p class="price-item">
                              R$
                              {{
                                formatMoney(
                                  select_itens_personalization[index]
                                    ? select_itens_personalization[index].price
                                    : 0
                                )
                              }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div
                        class="itens-pedido"
                        v-for="kit_item in custom.kits"
                        :key="kit_item.kit.id"
                      >
                        <h5>
                          {{ kit_item.kit.name }}
                        </h5>

                        {{ kit_item.kit.gallery }}

                        <div class="item-pedido">
                          <img
                            v-if="kit_item.kit"
                            :src="kit_item.kit.image || ''"
                            class="img-item"
                            alt=""
                          />
                          <div class="info-itens">
                            <div class="info-item">
                              <div class="item-dado">
                                <span>DETALHES</span>
                                <p
                                  v-for="(group, key) in kit_item.kit.products"
                                  :key="key"
                                >
                                  {{ key }} - ({{
                                    group
                                      .map((elem) => {
                                        return (
                                          elem.product.quantity +
                                          " " +
                                          elem.product.name
                                        );
                                      })
                                      .join(" | ")
                                  }})
                                </p>
                              </div>
                            </div>
                            <p class="obs-item">
                              {{ kit_item.kit.description }}
                            </p>
                          </div>
                          <div>
                            <p class="price-item">
                              R$
                              {{
                                formatMoney(
                                  kit_item.kit ? kit_item.kit.price : 0
                                )
                              }}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div class="rodape-pedido-lista">
                        <div class="item-assinatura">
                          <span>COMPRADOR</span>
                          <p>
                            {{ custom.user.first_name }}
                            {{ custom.user.last_name }}
                          </p>
                        </div>
                        <div class="item-assinatura">
                          <span>VENDEDOR</span>
                          <p>{{ custom.enterprise.name }}</p>
                        </div>

                        <div class="item-assinatura price">
                          <span>TOTAL PEDIDO</span>
                          <p>R$ {{ formatMoney(custom.price) }}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="!itens_personalization.length"
                      data-anima="bottom"
                      class="itens-pedido text-center mt-5"
                    >
                      Não Itens/Kits para exibir
                    </div>
                  </div>
                  <div class="col-12 cancela-col-padding" v-if="loading_itens">
                    <div class="loading">
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 cancela-col-padding" v-if="loading">
              <div class="loading">
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomItemUser from "@/components/Kit/CustomItemUser.vue";

import OrderService from "@/services/resources/OrderService";
const serviceOrder = OrderService.build();

import TriggerService from "@/services/resources/TriggerService";
const serviceTrigger = TriggerService.build();

import KitConfigService from "@/services/resources/KitConfigService";
const serviceKit = KitConfigService.build();

import Money from "@/mixins/money";

export default {
  name: "MyCustomizationItem",
  mixins: [Money],

  data() {
    return {
      loading: false,
      loading_itens: false,
      custom: null,
      triggers: [],
      itens_personalization: [],
      select_itens_personalization: [],
    };
  },
  components: {
    CustomItemUser,
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },

  methods: {
    async selectKit(id) {
      return new Promise((resolve) => {
        if (!id) {
          resolve(null);
        }

        let data = {
          id: id,
        };

        serviceKit
          .read(data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            console.log(err);
            resolve(null);
          });
      });
    },
    async configItens() {
      // if (!this.custom.itens) {
      //   this.loading_itens = false;
      //   return;
      // }
      this.custom.itens.forEach((element) => {
        this.selectKit(element.kit_config_id).then((resp) => {
          if (resp.type == "PERSONALIZATION_TYPE") {
            const data = {
              ...resp,
              trigger: this.triggers
                ? this.triggers.itens.find((t) => t.kit_config_id == resp.id)
                : null,
            };
            this.itens_personalization.push(data);
          } else if (resp.type == "PERSONALIZATION_TYPE_ITEM") {
            this.select_itens_personalization.push(resp);
          }
        });
      });

      setTimeout(() => {
        this.loading_itens = false;
      }, 500);
    },
    fetchTrigger() {
      let data = {
        tipology_id: this.custom.tipology_id,
      };
      serviceTrigger
        .search(data)
        .then((resp) => {
          this.triggers = resp.data;
          this.configItens();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchCustom(id_custom) {
      this.loading = true;
      this.loading_itens = true;
      serviceOrder
        .read(`/user/${this.user.id}/${id_custom}`)
        .then((resp) => {
          this.custom = resp.data[0];
          this.fetchTrigger();
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    window.scrollTo({ top: 0, behavior: "smooth" });
    this.fetchCustom(this.$route.params.id_custom);
  },
};
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
<style scoped>
.container-fluid {
  min-height: 80vh;
}

.loading {
  min-height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-pedido {
  display: grid;
  grid-template-columns: 370px 1fr;
  gap: 20px;
  padding: 50px 0;
}
.left-side .logo {
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}
.left-side h4 {
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  text-align: center;
  margin-bottom: 15px;
}

/* lado direito */
.right-side {
  /* background: red; */
}
.right-side .header-custom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.right-side .header-custom h4 {
  font-size: 22px;
  font-weight: 600;
  color: rgba(47, 47, 47, 0.7);
  margin: 0;
}

.right-side .header-custom .total span {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 600;
  letter-spacing: 2px;
  text-align: right;
  display: block;
}
.right-side .header-custom .total p {
  color: var(--color);
  font-size: 28px;
  font-weight: 600;
  margin: 0;
  text-align: right;
}
.itens-pedido {
  /* background: red; */
}
.itens-pedido h5 {
  color: #acaca9;
  font-weight: 600;
  margin-left: 30px;
  margin-bottom: 10px;
  margin-top: 30px;
  text-transform: uppercase;
}
.item-pedido {
  background: rgba(244, 244, 246, 0.5);
  padding: 30px;
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  /* display: grid; */
  /* grid-template-columns: 1fr 1fr 4fr 2fr; */
}
.item-pedido .img-item {
  max-width: 140px;
  height: 140px;
  border-radius: 16px;
}

.item-pedido .img-pedra {
  max-width: 80px;
  height: 80px;
  border-radius: 10px;
}
.info-itens .info-item {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
  /* gap: 20px; */
  gap: 10px;
}
.info-itens .info-item .item-dado {
  width: 100%;
}
.info-itens .info-item .item-dado span {
  font-size: 11px;
  font-weight: 600;
  color: rgba(47, 47, 47, 0.4);
}
.info-itens .info-item .item-dado p {
  color: #2f2f2f;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.info-itens .obs-item {
  color: rgba(47, 47, 47, 0.4);
  font-weight: 500;
  font-size: 13px;
  max-width: 80%;
  margin-top: 7px;
}
.item-pedido .price-item {
  color: var(--color);
  font-weight: 600;
  font-size: 22px;
  text-align: right;
  min-width: 160px;
}
.rodape-pedido-lista {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 60px 30px;
  gap: 80px;
}
.rodape-pedido-lista .item-assinatura {
  border-top: 1px solid #83838365;
  padding-top: 15px;
}
.rodape-pedido-lista .item-assinatura.price {
  border: none;
  text-align: right;
}
.rodape-pedido-lista .item-assinatura span {
  color: #9e9e9a;
  font-size: 13px;
}
.rodape-pedido-lista .item-assinatura p {
  color: rgba(46, 46, 46, 0.8);
  font-size: 18px;
  text-transform: uppercase;
  margin: 0;
  font-weight: 600;
}
.rodape-pedido-lista .item-assinatura.price p {
  color: var(--color);
  font-size: 28px;
}
@media screen and (max-width: 768px) {
  .cancela-col-padding {
    padding: 0 !important;
  }
  .container-pedido {
    /* display: block; */
    grid-template-columns: 1fr;
    padding: 30px 0;
  }
  .left-side .logo {
    margin: 0;
    max-height: 30px !important;
  }
  .left-side h4 {
    text-align: left;
    font-size: 12px;
    margin-top: 20px;
  }
  .right-side .header-custom {
    display: block;
  }
  .right-side .header-custom h4 {
    font-size: 16px;
    margin-bottom: 15px;
  }

  .right-side .header-custom .total span {
    font-size: 12px;
    letter-spacing: 0;
    text-align: left;
  }
  .right-side .header-custom .total p {
    font-size: 22px;
    text-align: left;
  }
  .itens-pedido h5 {
    font-size: 14px;
    margin-left: 0;
  }
  .item-pedido {
    padding: 20px;
    border-radius: 8px;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .item-pedido .img-item {
    max-width: 120px;
    height: 120px;
    border-radius: 8px;
  }

  .item-pedido .img-pedra {
    max-width: 60px;
    height: 60px;
    border-radius: 8px;
  }

  .info-itens .info-item .item-dado span {
    font-size: 10px;
  }
  .info-itens .info-item .item-dado p {
    font-size: 13px;
  }
  .info-itens .obs-item {
    font-size: 12px;
    max-width: 100%;
    margin: 0;
  }
  .item-pedido .price-item {
    font-size: 18px;
    text-align: left;
    min-width: 100%;
  }
  .rodape-pedido-lista {
    grid-template-columns: 1fr;
    align-items: center;
    padding: 50px 0px;
    gap: 50px;
  }
  .rodape-pedido-lista .item-assinatura {
    padding-top: 10px;
  }
  .rodape-pedido-lista .item-assinatura.price {
    text-align: left;
  }
  .rodape-pedido-lista .item-assinatura span {
    color: #9e9e9a;
    font-size: 12px;
  }
  .rodape-pedido-lista .item-assinatura p {
    font-size: 14px;
  }
  .rodape-pedido-lista .item-assinatura.price p {
    color: var(--color);
    font-size: 22px;
  }
}
</style>